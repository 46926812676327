export const FirebaseConfig = {
	"projectId": "cuconon-dd8d0",
	"appId": "1:831236890030:web:aecd0d0c759741fc6dbc3f",
	"databaseURL": "https://cuconon-dd8d0-default-rtdb.firebaseio.com",
	"storageBucket": "cuconon-dd8d0.appspot.com",
	"locationId": "us-central",
	"apiKey": "AIzaSyAJitKOEnqeF0O9ESQtAoWOgaeCfN_Z77A",
	"authDomain": "cuconon-dd8d0.firebaseapp.com",
	"messagingSenderId": "831236890030",
	"measurementId": "G-3YKZYRH974"
};